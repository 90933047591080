
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function verifyFitmentRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry4(entry, index) {
                    return _createElement('div', {
                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3520'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3722'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                    'key': '39'
                }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled
                }, this.useNativeDropdown ? [
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n          ', '', this.title, '\n        ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7541'
                        }, 'Popular Makes'),
                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7544'
                        }, 'All Makes'),
                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                    ] : null,
                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                ] : null, !this.useNativeDropdown ? _createElement('option', {
                    'key': '_current',
                    'value': '_current'
                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                            return _createElement('div', { 'className': 'cm_filterInput' });
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'key': '2842'
                    },
                    !this.hideNullOption ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'onClick': () => this.onChange('')
                    }, '\n      ', '', this.title, '\n    ') : null,
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, '...loading...') : null,
                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__verify-fitment' }, this.template === 'active' ? _createElement('div', {
        'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
        'onClick': this.openDialog,
        'key': '68'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, _createElement('svg', {
        'width': '26',
        'height': '25',
        'viewBox': '0 0 26 25',
        'fill': 'none',
        'xmlns': 'http://www.w3.org/2000/svg'
    }, _createElement('rect', {
        'x': '1.96875',
        'y': '1.5',
        'width': '22',
        'height': '22',
        'rx': '11',
        'fill': '#020202',
        'stroke': '#020202',
        'strokeWidth': '2'
    }), _createElement('path', {
        'd': 'M14.4904 6.368L14.1704 14.032H11.8504L11.5304 6.368H14.4904ZM13.0504 18.128C12.5704 18.128 12.1758 17.9893 11.8664 17.712C11.5678 17.424 11.4184 17.072 11.4184 16.656C11.4184 16.2293 11.5678 15.872 11.8664 15.584C12.1758 15.296 12.5704 15.152 13.0504 15.152C13.5198 15.152 13.9038 15.296 14.2024 15.584C14.5118 15.872 14.6664 16.2293 14.6664 16.656C14.6664 17.072 14.5118 17.424 14.2024 17.712C13.9038 17.9893 13.5198 18.128 13.0504 18.128Z',
        'fill': 'white'
    })), '\n      CHECK IF THIS FITS YOUR VEHICLE\n    ')) : null, this.template === 'locked' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__' + this.fits + ' cmTemplate_locked',
        'key': '1007'
    }, _createElement('div', { 'className': 'cm_verify-fitment_fitment__container' }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' ? [_createElement('svg', {
            'xmlns': 'http://www.w3.org/2000/svg',
            'fill': 'none',
            'viewBox': '0 0 20 20',
            'key': '12590'
        }, _createElement('rect', {
            'width': '18',
            'height': '18',
            'x': '1',
            'y': '1',
            'fill': '#48C11E',
            'stroke': '#48C11E',
            'strokeWidth': '2',
            'rx': '9'
        }), _createElement('path', {
            'stroke': '#fff',
            'strokeLinecap': 'square',
            'strokeLinejoin': 'round',
            'strokeWidth': '2',
            'd': 'm6.155 10.585 2.354 2.353 6.015-6.015'
        }))] : null, this.fits === 'universal' ? [_createElement('svg', {
            'width': '20',
            'height': '20',
            'viewBox': '0 0 20 20',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '16350'
        }, _createElement('rect', {
            'x': '1',
            'y': '1',
            'width': '18',
            'height': '18',
            'rx': '9',
            'fill': '#FF6800',
            'stroke': '#FF6800',
            'strokeWidth': '2'
        }), _createElement('g', { 'clipPath': 'url(#clip0_828_47067)' }, _createElement('path', {
            'd': 'M14.993 5.07788L4.99304 15.0779',
            'stroke': 'white',
            'strokeWidth': '2',
            'strokeLinecap': 'square',
            'strokeLinejoin': 'round'
        }), _createElement('path', {
            'd': 'M4.99304 5.07788L14.993 15.0779',
            'stroke': 'white',
            'strokeWidth': '2',
            'strokeLinecap': 'square',
            'strokeLinejoin': 'round'
        })), _createElement('rect', {
            'x': '1',
            'y': '1',
            'width': '18',
            'height': '18',
            'rx': '9',
            'fill': '#FFB800',
            'stroke': '#FFB800',
            'strokeWidth': '2'
        }), _createElement('g', { 'clipPath': 'url(#clip1_828_47067)' }, _createElement('path', {
            'd': 'M11.4592 4.368L11.1392 12.032H8.81919L8.49919 4.368H11.4592ZM10.0192 16.128C9.53919 16.128 9.14452 15.9893 8.83519 15.712C8.53652 15.424 8.38719 15.072 8.38719 14.656C8.38719 14.2293 8.53652 13.872 8.83519 13.584C9.14452 13.296 9.53919 13.152 10.0192 13.152C10.4885 13.152 10.8725 13.296 11.1712 13.584C11.4805 13.872 11.6352 14.2293 11.6352 14.656C11.6352 15.072 11.4805 15.424 11.1712 15.712C10.8725 15.9893 10.4885 16.128 10.0192 16.128Z',
            'fill': 'white'
        })), _createElement('defs', {}, _createElement('clipPath', { 'id': 'clip0_828_47067' }, _createElement('rect', {
            'width': '10',
            'height': '10',
            'fill': 'white',
            'transform': 'translate(5 5)'
        })), _createElement('clipPath', { 'id': 'clip1_828_47067' }, _createElement('rect', {
            'width': '20',
            'height': '20',
            'fill': 'white'
        }))))] : null, this.fits === 'no' ? [_createElement('svg', {
            'width': '20',
            'height': '20',
            'viewBox': '0 0 20 20',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '30740'
        }, _createElement('rect', {
            'x': '1',
            'y': '1',
            'width': '18',
            'height': '18',
            'rx': '9',
            'fill': '#FF0000',
            'stroke': '#FF0000',
            'strokeWidth': '2'
        }), _createElement('g', { 'clipPath': 'url(#clip0_819_52756)' }, _createElement('path', {
            'd': 'M14.993 5.07788L4.99304 15.0779',
            'stroke': 'white',
            'strokeWidth': '2',
            'strokeLinecap': 'square',
            'strokeLinejoin': 'round'
        }), _createElement('path', {
            'd': 'M4.99304 5.07788L14.993 15.0779',
            'stroke': 'white',
            'strokeWidth': '2',
            'strokeLinecap': 'square',
            'strokeLinejoin': 'round'
        })), _createElement('defs', {}, _createElement('clipPath', { 'id': 'clip0_819_52756' }, _createElement('rect', {
            'width': '10',
            'height': '10',
            'fill': 'white',
            'transform': 'translate(5 5)'
        }))))] : null, this.fits === 'unknown' ? [_createElement('svg', {
            'width': '20',
            'height': '20',
            'viewBox': '0 0 20 20',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '37880'
        }, _createElement('rect', {
            'x': '1',
            'y': '1',
            'width': '18',
            'height': '18',
            'rx': '9',
            'fill': '#FF6800',
            'stroke': '#FF6800',
            'strokeWidth': '2'
        }), _createElement('g', { 'clipPath': 'url(#clip0_828_47067)' }, _createElement('path', {
            'd': 'M14.993 5.07788L4.99304 15.0779',
            'stroke': 'white',
            'strokeWidth': '2',
            'strokeLinecap': 'square',
            'strokeLinejoin': 'round'
        }), _createElement('path', {
            'd': 'M4.99304 5.07788L14.993 15.0779',
            'stroke': 'white',
            'strokeWidth': '2',
            'strokeLinecap': 'square',
            'strokeLinejoin': 'round'
        })), _createElement('rect', {
            'x': '1',
            'y': '1',
            'width': '18',
            'height': '18',
            'rx': '9',
            'fill': '#FFB800',
            'stroke': '#FFB800',
            'strokeWidth': '2'
        }), _createElement('g', { 'clipPath': 'url(#clip1_828_47067)' }, _createElement('path', {
            'd': 'M11.4592 4.368L11.1392 12.032H8.81919L8.49919 4.368H11.4592ZM10.0192 16.128C9.53919 16.128 9.14452 15.9893 8.83519 15.712C8.53652 15.424 8.38719 15.072 8.38719 14.656C8.38719 14.2293 8.53652 13.872 8.83519 13.584C9.14452 13.296 9.53919 13.152 10.0192 13.152C10.4885 13.152 10.8725 13.296 11.1712 13.584C11.4805 13.872 11.6352 14.2293 11.6352 14.656C11.6352 15.072 11.4805 15.424 11.1712 15.712C10.8725 15.9893 10.4885 16.128 10.0192 16.128Z',
            'fill': 'white'
        })), _createElement('defs', {}, _createElement('clipPath', { 'id': 'clip0_828_47067' }, _createElement('rect', {
            'width': '10',
            'height': '10',
            'fill': 'white',
            'transform': 'translate(5 5)'
        })), _createElement('clipPath', { 'id': 'clip1_828_47067' }, _createElement('rect', {
            'width': '20',
            'height': '20',
            'fill': 'white'
        }))))] : null), _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, this.fits === 'yes' ? ['This product fits your vehicle'] : null, this.fits === 'no' ? ['This product doesn\'t fit your vehicle'] : null), _createElement('div', { 'className': 'cm_verify-fitment_body' }, _createElement('span', { 'className': 'cm_verify-fitment_vehicle' }, this.vehicleString || 'Vehicle'), _createElement('a', {
        'className': 'cm_verify-fitment_change',
        'onClick': this.openDialog
    }, 'Change'))))) : null, this.template === 'inexact' ? _createElement('div', {
        'className': 'cm_vehicle-widget_specifier cmTemplate_inexact',
        'key': '5834'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, 'Please specify ', this.selectFields.join(', '), ' for your ', this.vehicleString), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects1.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button button button--primary cm_button cm_button__primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle
    }, '\n    GO\n  ')))) : null, this.template === 'universal' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__universal cmTemplate_universal',
        'key': '12158'
    }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' ? [_createElement('svg', {
            'xmlns': 'http://www.w3.org/2000/svg',
            'fill': 'none',
            'viewBox': '0 0 20 20',
            'key': '123570'
        }, _createElement('rect', {
            'width': '18',
            'height': '18',
            'x': '1',
            'y': '1',
            'fill': '#48C11E',
            'stroke': '#48C11E',
            'strokeWidth': '2',
            'rx': '9'
        }), _createElement('path', {
            'stroke': '#fff',
            'strokeLinecap': 'square',
            'strokeLinejoin': 'round',
            'strokeWidth': '2',
            'd': 'm6.155 10.585 2.354 2.353 6.015-6.015'
        }))] : null, this.fits === 'universal' ? [_createElement('svg', {
            'width': '20',
            'height': '20',
            'viewBox': '0 0 20 20',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '127330'
        }, _createElement('rect', {
            'x': '1',
            'y': '1',
            'width': '18',
            'height': '18',
            'rx': '9',
            'fill': '#FF6800',
            'stroke': '#FF6800',
            'strokeWidth': '2'
        }), _createElement('g', { 'clipPath': 'url(#clip0_828_47067)' }, _createElement('path', {
            'd': 'M14.993 5.07788L4.99304 15.0779',
            'stroke': 'white',
            'strokeWidth': '2',
            'strokeLinecap': 'square',
            'strokeLinejoin': 'round'
        }), _createElement('path', {
            'd': 'M4.99304 5.07788L14.993 15.0779',
            'stroke': 'white',
            'strokeWidth': '2',
            'strokeLinecap': 'square',
            'strokeLinejoin': 'round'
        })), _createElement('rect', {
            'x': '1',
            'y': '1',
            'width': '18',
            'height': '18',
            'rx': '9',
            'fill': '#FFB800',
            'stroke': '#FFB800',
            'strokeWidth': '2'
        }), _createElement('g', { 'clipPath': 'url(#clip1_828_47067)' }, _createElement('path', {
            'd': 'M11.4592 4.368L11.1392 12.032H8.81919L8.49919 4.368H11.4592ZM10.0192 16.128C9.53919 16.128 9.14452 15.9893 8.83519 15.712C8.53652 15.424 8.38719 15.072 8.38719 14.656C8.38719 14.2293 8.53652 13.872 8.83519 13.584C9.14452 13.296 9.53919 13.152 10.0192 13.152C10.4885 13.152 10.8725 13.296 11.1712 13.584C11.4805 13.872 11.6352 14.2293 11.6352 14.656C11.6352 15.072 11.4805 15.424 11.1712 15.712C10.8725 15.9893 10.4885 16.128 10.0192 16.128Z',
            'fill': 'white'
        })), _createElement('defs', {}, _createElement('clipPath', { 'id': 'clip0_828_47067' }, _createElement('rect', {
            'width': '10',
            'height': '10',
            'fill': 'white',
            'transform': 'translate(5 5)'
        })), _createElement('clipPath', { 'id': 'clip1_828_47067' }, _createElement('rect', {
            'width': '20',
            'height': '20',
            'fill': 'white'
        }))))] : null, this.fits === 'no' ? [_createElement('svg', {
            'width': '20',
            'height': '20',
            'viewBox': '0 0 20 20',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '141720'
        }, _createElement('rect', {
            'x': '1',
            'y': '1',
            'width': '18',
            'height': '18',
            'rx': '9',
            'fill': '#FF0000',
            'stroke': '#FF0000',
            'strokeWidth': '2'
        }), _createElement('g', { 'clipPath': 'url(#clip0_819_52756)' }, _createElement('path', {
            'd': 'M14.993 5.07788L4.99304 15.0779',
            'stroke': 'white',
            'strokeWidth': '2',
            'strokeLinecap': 'square',
            'strokeLinejoin': 'round'
        }), _createElement('path', {
            'd': 'M4.99304 5.07788L14.993 15.0779',
            'stroke': 'white',
            'strokeWidth': '2',
            'strokeLinecap': 'square',
            'strokeLinejoin': 'round'
        })), _createElement('defs', {}, _createElement('clipPath', { 'id': 'clip0_819_52756' }, _createElement('rect', {
            'width': '10',
            'height': '10',
            'fill': 'white',
            'transform': 'translate(5 5)'
        }))))] : null, this.fits === 'unknown' ? [_createElement('svg', {
            'width': '20',
            'height': '20',
            'viewBox': '0 0 20 20',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '148860'
        }, _createElement('rect', {
            'x': '1',
            'y': '1',
            'width': '18',
            'height': '18',
            'rx': '9',
            'fill': '#FF6800',
            'stroke': '#FF6800',
            'strokeWidth': '2'
        }), _createElement('g', { 'clipPath': 'url(#clip0_828_47067)' }, _createElement('path', {
            'd': 'M14.993 5.07788L4.99304 15.0779',
            'stroke': 'white',
            'strokeWidth': '2',
            'strokeLinecap': 'square',
            'strokeLinejoin': 'round'
        }), _createElement('path', {
            'd': 'M4.99304 5.07788L14.993 15.0779',
            'stroke': 'white',
            'strokeWidth': '2',
            'strokeLinecap': 'square',
            'strokeLinejoin': 'round'
        })), _createElement('rect', {
            'x': '1',
            'y': '1',
            'width': '18',
            'height': '18',
            'rx': '9',
            'fill': '#FFB800',
            'stroke': '#FFB800',
            'strokeWidth': '2'
        }), _createElement('g', { 'clipPath': 'url(#clip1_828_47067)' }, _createElement('path', {
            'd': 'M11.4592 4.368L11.1392 12.032H8.81919L8.49919 4.368H11.4592ZM10.0192 16.128C9.53919 16.128 9.14452 15.9893 8.83519 15.712C8.53652 15.424 8.38719 15.072 8.38719 14.656C8.38719 14.2293 8.53652 13.872 8.83519 13.584C9.14452 13.296 9.53919 13.152 10.0192 13.152C10.4885 13.152 10.8725 13.296 11.1712 13.584C11.4805 13.872 11.6352 14.2293 11.6352 14.656C11.6352 15.072 11.4805 15.424 11.1712 15.712C10.8725 15.9893 10.4885 16.128 10.0192 16.128Z',
            'fill': 'white'
        })), _createElement('defs', {}, _createElement('clipPath', { 'id': 'clip0_828_47067' }, _createElement('rect', {
            'width': '10',
            'height': '10',
            'fill': 'white',
            'transform': 'translate(5 5)'
        })), _createElement('clipPath', { 'id': 'clip1_828_47067' }, _createElement('rect', {
            'width': '20',
            'height': '20',
            'fill': 'white'
        }))))] : null), _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, window.Convermax.config?.verifyFitmentUniversalTitle || 'Universal Fit'), _createElement('div', { 'className': 'cm_verify-fitment_notes' }, window.Convermax.config?.verifyFitmentUniversalText || 'This product may require modification.'))) : null, this.template === 'unknown' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__unknown cmTemplate_unknown',
        'key': '16681'
    }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' ? [_createElement('svg', {
            'xmlns': 'http://www.w3.org/2000/svg',
            'fill': 'none',
            'viewBox': '0 0 20 20',
            'key': '168740'
        }, _createElement('rect', {
            'width': '18',
            'height': '18',
            'x': '1',
            'y': '1',
            'fill': '#48C11E',
            'stroke': '#48C11E',
            'strokeWidth': '2',
            'rx': '9'
        }), _createElement('path', {
            'stroke': '#fff',
            'strokeLinecap': 'square',
            'strokeLinejoin': 'round',
            'strokeWidth': '2',
            'd': 'm6.155 10.585 2.354 2.353 6.015-6.015'
        }))] : null, this.fits === 'universal' ? [_createElement('svg', {
            'width': '20',
            'height': '20',
            'viewBox': '0 0 20 20',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '172500'
        }, _createElement('rect', {
            'x': '1',
            'y': '1',
            'width': '18',
            'height': '18',
            'rx': '9',
            'fill': '#FF6800',
            'stroke': '#FF6800',
            'strokeWidth': '2'
        }), _createElement('g', { 'clipPath': 'url(#clip0_828_47067)' }, _createElement('path', {
            'd': 'M14.993 5.07788L4.99304 15.0779',
            'stroke': 'white',
            'strokeWidth': '2',
            'strokeLinecap': 'square',
            'strokeLinejoin': 'round'
        }), _createElement('path', {
            'd': 'M4.99304 5.07788L14.993 15.0779',
            'stroke': 'white',
            'strokeWidth': '2',
            'strokeLinecap': 'square',
            'strokeLinejoin': 'round'
        })), _createElement('rect', {
            'x': '1',
            'y': '1',
            'width': '18',
            'height': '18',
            'rx': '9',
            'fill': '#FFB800',
            'stroke': '#FFB800',
            'strokeWidth': '2'
        }), _createElement('g', { 'clipPath': 'url(#clip1_828_47067)' }, _createElement('path', {
            'd': 'M11.4592 4.368L11.1392 12.032H8.81919L8.49919 4.368H11.4592ZM10.0192 16.128C9.53919 16.128 9.14452 15.9893 8.83519 15.712C8.53652 15.424 8.38719 15.072 8.38719 14.656C8.38719 14.2293 8.53652 13.872 8.83519 13.584C9.14452 13.296 9.53919 13.152 10.0192 13.152C10.4885 13.152 10.8725 13.296 11.1712 13.584C11.4805 13.872 11.6352 14.2293 11.6352 14.656C11.6352 15.072 11.4805 15.424 11.1712 15.712C10.8725 15.9893 10.4885 16.128 10.0192 16.128Z',
            'fill': 'white'
        })), _createElement('defs', {}, _createElement('clipPath', { 'id': 'clip0_828_47067' }, _createElement('rect', {
            'width': '10',
            'height': '10',
            'fill': 'white',
            'transform': 'translate(5 5)'
        })), _createElement('clipPath', { 'id': 'clip1_828_47067' }, _createElement('rect', {
            'width': '20',
            'height': '20',
            'fill': 'white'
        }))))] : null, this.fits === 'no' ? [_createElement('svg', {
            'width': '20',
            'height': '20',
            'viewBox': '0 0 20 20',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '186890'
        }, _createElement('rect', {
            'x': '1',
            'y': '1',
            'width': '18',
            'height': '18',
            'rx': '9',
            'fill': '#FF0000',
            'stroke': '#FF0000',
            'strokeWidth': '2'
        }), _createElement('g', { 'clipPath': 'url(#clip0_819_52756)' }, _createElement('path', {
            'd': 'M14.993 5.07788L4.99304 15.0779',
            'stroke': 'white',
            'strokeWidth': '2',
            'strokeLinecap': 'square',
            'strokeLinejoin': 'round'
        }), _createElement('path', {
            'd': 'M4.99304 5.07788L14.993 15.0779',
            'stroke': 'white',
            'strokeWidth': '2',
            'strokeLinecap': 'square',
            'strokeLinejoin': 'round'
        })), _createElement('defs', {}, _createElement('clipPath', { 'id': 'clip0_819_52756' }, _createElement('rect', {
            'width': '10',
            'height': '10',
            'fill': 'white',
            'transform': 'translate(5 5)'
        }))))] : null, this.fits === 'unknown' ? [_createElement('svg', {
            'width': '20',
            'height': '20',
            'viewBox': '0 0 20 20',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '194030'
        }, _createElement('rect', {
            'x': '1',
            'y': '1',
            'width': '18',
            'height': '18',
            'rx': '9',
            'fill': '#FF6800',
            'stroke': '#FF6800',
            'strokeWidth': '2'
        }), _createElement('g', { 'clipPath': 'url(#clip0_828_47067)' }, _createElement('path', {
            'd': 'M14.993 5.07788L4.99304 15.0779',
            'stroke': 'white',
            'strokeWidth': '2',
            'strokeLinecap': 'square',
            'strokeLinejoin': 'round'
        }), _createElement('path', {
            'd': 'M4.99304 5.07788L14.993 15.0779',
            'stroke': 'white',
            'strokeWidth': '2',
            'strokeLinecap': 'square',
            'strokeLinejoin': 'round'
        })), _createElement('rect', {
            'x': '1',
            'y': '1',
            'width': '18',
            'height': '18',
            'rx': '9',
            'fill': '#FFB800',
            'stroke': '#FFB800',
            'strokeWidth': '2'
        }), _createElement('g', { 'clipPath': 'url(#clip1_828_47067)' }, _createElement('path', {
            'd': 'M11.4592 4.368L11.1392 12.032H8.81919L8.49919 4.368H11.4592ZM10.0192 16.128C9.53919 16.128 9.14452 15.9893 8.83519 15.712C8.53652 15.424 8.38719 15.072 8.38719 14.656C8.38719 14.2293 8.53652 13.872 8.83519 13.584C9.14452 13.296 9.53919 13.152 10.0192 13.152C10.4885 13.152 10.8725 13.296 11.1712 13.584C11.4805 13.872 11.6352 14.2293 11.6352 14.656C11.6352 15.072 11.4805 15.424 11.1712 15.712C10.8725 15.9893 10.4885 16.128 10.0192 16.128Z',
            'fill': 'white'
        })), _createElement('defs', {}, _createElement('clipPath', { 'id': 'clip0_828_47067' }, _createElement('rect', {
            'width': '10',
            'height': '10',
            'fill': 'white',
            'transform': 'translate(5 5)'
        })), _createElement('clipPath', { 'id': 'clip1_828_47067' }, _createElement('rect', {
            'width': '20',
            'height': '20',
            'fill': 'white'
        }))))] : null), _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, 'No Fitment Data'), _createElement('div', { 'className': 'cm_verify-fitment_notes' }, 'This product doesn\'t have fitment application data. Please verify fitment manually.'))) : null);
}
        export const componentNames = ["cm:filterInput"]