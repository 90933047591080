const getVerifyFitmentLabelHtml = (fits, vehicleLabel) => {
  const icon =
    fits === 'yes'
      ? `
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
        <rect width="18" height="18" x="1" y="1" fill="#48C11E" stroke="#48C11E" stroke-width="2" rx="9"></rect>
        <path stroke="#fff" stroke-linecap="square" stroke-linejoin="round" stroke-width="2" d="m6.155 10.585 2.354 2.353 6.015-6.015"></path>
      </svg>`
      : `
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="18" height="18" rx="9" fill="#FF0000" stroke="#FF0000" strokeWidth="2" />
        <g clipPath="url(#clip0_819_52756)">
          <path d="M14.993 5.07788L4.99304 15.0779" stroke="white" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
          <path d="M4.99304 5.07788L14.993 15.0779" stroke="white" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
        </g>
        <defs><clipPath id="clip0_819_52756"><rect width="10" height="10" fill="white" transform="translate(5 5)" /></clipPath></defs>
      </svg>`;

  return `
    <div class="cm_vehicle-widget cm_vehicle-widget__verify-fitment cm_cart">
      <div class="cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__${fits}">
        <div class="cm_verify-fitment_icon">
          ${icon}
        </div>
        <div class="cm_verify-fitment_fitment">
          <div class="cm_verify-fitment_title">This product ${
            fits === 'yes' ? 'fits' : 'doesn’t fit'
          } your vehicle:&nbsp;</div>
          <div class="cm_verify-fitment_body">
            <span class="cm_verify-fitment_vehicle">${vehicleLabel}</span>
          </div>
        </div>
      </div>
    </div>
  `;
};

const updateCartVerifyFitmentLabels = (productIds, vehicle) => {
  const vehicleLabel = Object.values(vehicle).join(' ');

  productIds.forEach((productId) => {
    window.Convermax.verifyFitmentAsync(productId.toString(), vehicle).then((fits) => {
      if (fits !== 'yes' && fits !== 'no') {
        return;
      }

      const containerElem = window.document.getElementById(`cm_verify-fitment-label-container-${productId}`);

      if (containerElem && !containerElem.childNodes.length) {
        const verifyFitmentLabel = getVerifyFitmentLabelHtml(fits, vehicleLabel);
        containerElem.innerHTML = verifyFitmentLabel;
      }
    });
  });
};

export const AfterInit = () => {
  const vehicle = window.Convermax.getVehicle();
  if (window.document.body.classList.contains('template-cart') && Object.keys(vehicle).length > 0) {
    document.addEventListener('theme:cart:change', (e) => {
      const productIds = e.detail?.cart.items.map((item) => item.product_id);

      if (productIds) {
        setTimeout(() => {
          updateCartVerifyFitmentLabels(productIds, vehicle);
        }, 1500);
      }
    });
  }
};
