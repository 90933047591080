//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-556:_5196,_2536,_9280,_160,_9672,_956,_7376,_3152;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-556')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-556', "_5196,_2536,_9280,_160,_9672,_956,_7376,_3152");
        }
      }catch (ex) {
        console.error(ex);
      }