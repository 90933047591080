
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function customVehicleWidgetRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n        ', entry.value, '\n      ');
                }
                return _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle cm_select_toggle__native cm_toggle-dropdown' }, this.index || this.index === 0 ? _createElement('span', {
                    'className': 'index',
                    'key': '117'
                }, this.index + 1) : null, _createElement.apply(this, [
                    'select',
                    {
                        'className': 'cm_select_inner-select cm_select__pretty',
                        'onChange': this.onChange,
                        'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                        'disabled': this.disabled
                    },
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n        Select ', this.title, '\n      ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    _map(this.entries, repeatEntry1.bind(this))
                ])));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__search' }, this.template === 'active' ? _createElement('div', {
        'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
        'key': '60'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, _createElement('h2', {}, 'Select Your Vehicle'), _createElement('p', {}, 'Shop for your specific vehicle to find parts that fit.')), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container cmRepeater_selects' },
        _map(this.selects, repeatSelects1.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go',
        'onClick': e => window.Convermax.onSelectVehicleBtnClick(e, this.selectVehicle),
        'disabled': !this.allowToSetVehicle
    }, '\n          SEARCH\n        ')))) : null);
}
        export const componentNames = []