
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function headerVehicleWidgetRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n        ', entry.value, '\n      ');
                }
                return _createElement('div', { 'className': this.extraClassName }, _createElement('div', { 'className': 'cm_select_toggle cm_select_toggle__native cm_toggle-dropdown' }, this.index || this.index === 0 ? _createElement('span', {
                    'className': 'index',
                    'key': '117'
                }, this.index + 1) : null, _createElement.apply(this, [
                    'select',
                    {
                        'className': 'cm_select_inner-select cm_select__pretty',
                        'onChange': this.onChange,
                        'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                        'disabled': this.disabled
                    },
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n        Select ', this.title, '\n      ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    _map(this.entries, repeatEntry1.bind(this))
                ])));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'dialog-container  cm_vehicle-widget cm_vehicle-widget__dialog' }, _createElement('span', { 'className': 'cm_triangle' }), _createElement('div', {
        'className': 'close',
        'data-cm-role': 'close'
    }, _createElement('svg', {
        'width': '20',
        'height': '20',
        'viewBox': '0 0 20 20',
        'fill': 'none',
        'xmlns': 'http://www.w3.org/2000/svg'
    }, _createElement('path', {
        'd': 'M17 3L3 17',
        'stroke': '#232323',
        'strokeWidth': '3',
        'strokeLinecap': 'square',
        'strokeLinejoin': 'round'
    }), _createElement('path', {
        'd': 'M3 3L17 17',
        'stroke': '#232323',
        'strokeWidth': '3',
        'strokeLinecap': 'square',
        'strokeLinejoin': 'round'
    }))), _createElement('div', { 'className': 'dialog-content' }, _createElement('div', { 'className': 'dialog-body' }, _createElement('div', { 'className': 'cm_vehicle-widget_dropdowns' }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, _createElement('h2', {}, 'Select Your Vehicle'), _createElement('p', {}, 'Shop for your specific vehicle to find parts that fit.')), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container cmRepeater_selects' },
        _map(this.selects, repeatSelects1.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go',
        'onClick': e => window.Convermax.onSelectVehicleBtnClick(e, this.selectVehicle),
        'disabled': !this.allowToSetVehicle
    }, '\n    SEARCH\n  '), _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear',
        'onClick': this.discardVehicle,
        'disabled': !this.allowToDiscardVehicle
    }, [
        _createElement('span', {
            'className': 'text',
            'key': '28370'
        }, 'RESET'),
        _createElement('span', {
            'className': 'search-text',
            'key': '28372'
        }, 'RESET VEHICLE'),
        _createElement('svg', {
            'width': '22',
            'height': '23',
            'viewBox': '0 0 22 23',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '28374'
        }, _createElement('path', {
            'd': 'M0.476404 12.3127L4.14262 15.9795C4.44791 16.2848 4.86293 16.457 5.29534 16.457C5.72775 16.457 6.14277 16.2853 6.44752 15.9795L10.1143 12.3127C10.7504 11.6766 10.7504 10.6445 10.1143 10.0084C9.47816 9.37171 8.44604 9.37171 7.80992 10.0084L6.81474 11.0035C7.06896 7.94031 9.63732 5.5246 12.7652 5.5246C16.0593 5.5246 18.7407 8.20541 18.7407 11.5001C18.7407 14.7942 16.0593 17.4755 12.7652 17.4755C11.8651 17.4755 11.1355 18.2051 11.1355 19.1052C11.1355 20.0053 11.8651 20.7348 12.7652 20.7348C17.8568 20.7348 22 16.5922 22 11.5001C22 6.40788 17.8568 2.26526 12.7652 2.26526C7.91096 2.26526 3.92913 6.03143 3.56517 10.7928L2.78185 10.0084C2.14519 9.37171 1.11306 9.37171 0.477493 10.0084C-0.159164 10.6439 -0.159166 11.6761 0.476404 12.3127Z',
            'fill': '#FF6800'
        }))
    ])))))));
}
        export const componentNames = []