import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/_default-store/Templates/SearchPage.rt'
import { FacetPanel } from 'Components'
import TemplateMainContent from 'Stores/shock-surplus/Templates/MainContent.rt'
import TemplateFacetPanelContainer from 'Stores/shock-surplus/Templates/FacetPanelContainer.rt'
import { SearchBox } from 'Components'
import TemplateSearchBox from 'Stores/shock-surplus/Templates/SearchBox.rt'
import { SearchResult } from 'Components'
import { SearchHeader } from 'Components'
import { VehicleWidget } from 'Components'
import TemplatefitmentSearchhomeVehicleWidget from 'Stores/_default-store/Templates/fitmentSearch/homeVehicleWidget.rt'
import TemplateHeroBanner from 'Stores/shock-surplus/Templates/HeroBanner.rt'
import TemplatefitmentSearchheaderVehicleWidget from 'Stores/shock-surplus/Templates/fitmentSearch/headerVehicleWidget.rt'
import TemplatefitmentSearchcustomVehicleWidget from 'Stores/shock-surplus/Templates/fitmentSearch/customVehicleWidget.rt'
import { SingleVehicleGarage } from 'Components'
import TemplatefitmentSearchsingleVehicleGarage from 'Stores/shock-surplus/Templates/fitmentSearch/singleVehicleGarage.rt'
import TemplatefitmentSearchverifyFitment from 'Stores/shock-surplus/Templates/fitmentSearch/verifyFitment.rt'
import TemplatefitmentSearchverifyFitmentNew from 'Stores/shock-surplus/Templates/fitmentSearch/verifyFitmentNew.rt'
import { ContextDialog } from 'Components'
import TemplatefitmentSearchdialog from 'Stores/_default-store/Templates/fitmentSearch/dialog.rt'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/shock-surplus/Templates/FacetDialog.rt'

export const compMap = {
  SearchPage,
FacetPanel,
SearchBox,
SearchResult,
SearchHeader,
VehicleWidget,
SingleVehicleGarage,
ContextDialog,
FacetDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'MainContent': TemplateMainContent,
'FacetPanelContainer': TemplateFacetPanelContainer,
'SearchBox': TemplateSearchBox,
'fitmentSearch/homeVehicleWidget': TemplatefitmentSearchhomeVehicleWidget,
'HeroBanner': TemplateHeroBanner,
'fitmentSearch/headerVehicleWidget': TemplatefitmentSearchheaderVehicleWidget,
'fitmentSearch/customVehicleWidget': TemplatefitmentSearchcustomVehicleWidget,
'fitmentSearch/singleVehicleGarage': TemplatefitmentSearchsingleVehicleGarage,
'fitmentSearch/verifyFitment': TemplatefitmentSearchverifyFitment,
'fitmentSearch/verifyFitmentNew': TemplatefitmentSearchverifyFitmentNew,
'fitmentSearch/dialog': TemplatefitmentSearchdialog,
'FacetDialog': TemplateFacetDialog
};