
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function FacetPanelContainerRT () {
    return _createElement('div', { 'className': this.responseState === 'nothing' || this.responseState === 'badRequest' ? 'cm_hide' : '' }, [this.FacetPanel(function () {
            return _createElement('div', { 'className': 'cm_FacetPanel cm_facet-panel__full-width' }, [[this.filterChips(function () {
                        function repeatChips1(chips, chipsIndex) {
                            return [chips(function () {
                                    function repeatI1_reviewChip(i, iIndex) {
                                        return _createElement('span', {
                                            'key': `${ this.field }|${ i }`,
                                            'className': 'cm_star cm_star__' + (!(this.value - i) || this.value - i < 0.25 ? 'empty' : this.value - i < 0.75 ? 'half' : 'full')
                                        });
                                    }
                                    function scopeDollarInchMm1_rangeChip() {
                                        var dollar = [
                                            'price',
                                            'Price'
                                        ].includes(this.field) ? '$' : '';
                                        var inch = [].includes(this.field) ? '"' : '';
                                        var mm = [
                                            'wheel_bore',
                                            'wheel_offset'
                                        ].includes(this.field) ? 'mm' : '';
                                        return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '484' }, '\n    ', dollar, this.selection[0], inch, mm, ' to ', dollar, this.selection[1], inch, mm, '\n  ') : null, this.toPosInf ? _createElement(_Fragment, { 'key': '626' }, ' ', dollar, this.selection[0], inch, mm, ' and more ') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '732' }, ' ', dollar, this.selection[1], inch, mm, ' and less ') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                                'width': '20',
                                                'height': '20',
                                                'viewBox': '0 0 20 20',
                                                'fill': 'none',
                                                'xmlns': 'http://www.w3.org/2000/svg',
                                                'key': '8680'
                                            }, _createElement('path', {
                                                'd': 'M17 3L3 17',
                                                'stroke': '#232323',
                                                'strokeWidth': '3',
                                                'strokeLinecap': 'square',
                                                'strokeLinejoin': 'round'
                                            }), _createElement('path', {
                                                'd': 'M3 3L17 17',
                                                'stroke': '#232323',
                                                'strokeWidth': '3',
                                                'strokeLinecap': 'square',
                                                'strokeLinejoin': 'round'
                                            }))]));
                                    }
                                    return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'width': '20',
                                            'height': '20',
                                            'viewBox': '0 0 20 20',
                                            'fill': 'none',
                                            'xmlns': 'http://www.w3.org/2000/svg',
                                            'key': '2570'
                                        }, _createElement('path', {
                                            'd': 'M17 3L3 17',
                                            'stroke': '#232323',
                                            'strokeWidth': '3',
                                            'strokeLinecap': 'square',
                                            'strokeLinejoin': 'round'
                                        }), _createElement('path', {
                                            'd': 'M3 3L17 17',
                                            'stroke': '#232323',
                                            'strokeWidth': '3',
                                            'strokeLinecap': 'square',
                                            'strokeLinejoin': 'round'
                                        }))])) : this.template === 'reviewChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_reviewChip' }, _createElement.apply(this, [
                                        'a',
                                        {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        },
                                        _createElement('span', { 'className': 'cm_chip-name' }, this.name, ': '),
                                        _map([
                                            0,
                                            1,
                                            2,
                                            3,
                                            4
                                        ], repeatI1_reviewChip.bind(this)),
                                        this.value !== '5' ? _createElement('span', { 'key': '391' }, ' and up') : null
                                    ]), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'width': '20',
                                            'height': '20',
                                            'viewBox': '0 0 20 20',
                                            'fill': 'none',
                                            'xmlns': 'http://www.w3.org/2000/svg',
                                            'key': '4900'
                                        }, _createElement('path', {
                                            'd': 'M17 3L3 17',
                                            'stroke': '#232323',
                                            'strokeWidth': '3',
                                            'strokeLinecap': 'square',
                                            'strokeLinejoin': 'round'
                                        }), _createElement('path', {
                                            'd': 'M3 3L17 17',
                                            'stroke': '#232323',
                                            'strokeWidth': '3',
                                            'strokeLinecap': 'square',
                                            'strokeLinejoin': 'round'
                                        }))])) : this.template === 'rangeChip' ? scopeDollarInchMm1_rangeChip.apply(this, []) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                            'className': 'facettitle',
                            'tabIndex': '0'
                        }, 'Current search:'), _createElement('div', {
                            'className': 'facetbody',
                            'role': 'list'
                        }, _createElement.apply(this, [
                            'div',
                            { 'className': 'cmRepeater_chips' },
                            _map(this.chips, repeatChips1.bind(this))
                        ]), _createElement('div', {
                            'className': 'cm_btn cm_button cm_button__secondary cm_filter-chips_button',
                            'data-cm-role': 'start-over'
                        }, '\n        Start over\n      '))));
                    }, {
                        widgetName: 'filter-chips',
                        items: undefined
                    })]], [this.facets(function () {
                    function repeatFacets1(facets, facetsIndex) {
                        return [facets(function () {
                                function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues2(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues3(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, !this.isExtraRange ? _createElement('span', { 'key': '217' }, this.value) : null, this.isExtraRange && this.finite ? _createElement('span', { 'key': '278' }, '$', this.range[0], ' - $', this.range[1]) : null, this.isExtraRange && this.toPosInf ? _createElement('span', { 'key': '394' }, '$', this.range[0], ' and more') : null, this.isExtraRange && this.toNegInf ? _createElement('span', { 'key': '497' }, '$', this.range[1], ' and less') : null), !this.isExtraRange ? _createElement('div', {
                                                'className': 'facetentryval',
                                                'key': '609'
                                            }, this.hitCount) : null);
                                        }, { count: undefined })];
                                }
                                function scopeDollarInchMm4() {
                                    var dollar = [
                                        'price',
                                        'Price'
                                    ].includes(this.field) ? '$' : '';
                                    var inch = [].includes(this.field) ? '"' : '';
                                    var mm = [
                                        'wheel_bore',
                                        'wheel_offset'
                                    ].includes(this.field) ? 'mm' : '';
                                    return _createElement('div', {
                                        'className': 'cmTemplate_sliderFacet',
                                        'key': '12469'
                                    }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, '\n    Selected: ', dollar, this.selection[0], inch, mm, ' to ', dollar, this.selection[1], inch, mm, '\n  '), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, dollar, this.min, inch, mm), _createElement('div', { 'className': 'max' }, dollar, this.max, inch, mm), _createElement('div', { 'className': 'clear' }))), [this.Inputs(function () {
                                            return _createElement('div', { 'className': 'input cm_Inputs' }, this.inputMin ? [[this.inputMin(function () {
                                                        return _createElement('div', {
                                                            'placeholder': 'Min',
                                                            'className': 'cm_inputMin'
                                                        });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })]] : null, this.inputMin && this.inputMax ? _createElement('span', {
                                                'className': 'separator',
                                                'key': '334'
                                            }, '\u2014') : null, this.inputMax ? [[this.inputMax(function () {
                                                        return _createElement('div', {
                                                            'placeholder': 'Max',
                                                            'className': 'cm_inputMax'
                                                        });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })]] : null, _createElement('button', {
                                                'type': 'button',
                                                'className': 'cm_btn cm_button cm_button__secondary',
                                                'data-cm-role': 'add-facet'
                                            }, '\n    GO\n  '));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]);
                                }
                                function repeatShowAlwaysValues5(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            function scopeStarFill1(i, iIndex) {
                                                var starFill = this.value - i;
                                                return _createElement('span', {
                                                    'key': i,
                                                    'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                });
                                            }
                                            function repeatI2(i, iIndex, starFill) {
                                                return scopeStarFill1.apply(this, [
                                                    i,
                                                    iIndex
                                                ]);
                                            }
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, _createElement.apply(this, [
                                                'span',
                                                { 'className': 'cm_review-stars' },
                                                _map([
                                                    0,
                                                    1,
                                                    2,
                                                    3,
                                                    4
                                                ], repeatI2.bind(this))
                                            ]), this.value !== '5' ? _createElement('span', {
                                                'className': 'cm_and-up',
                                                'key': '499'
                                            }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues6(Values, ValuesIndex) {
                                    return [Values(function () {
                                            function scopeStarFill1(i, iIndex) {
                                                var starFill = this.value - i;
                                                return _createElement('span', {
                                                    'key': i,
                                                    'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                });
                                            }
                                            function repeatI2(i, iIndex, starFill) {
                                                return scopeStarFill1.apply(this, [
                                                    i,
                                                    iIndex
                                                ]);
                                            }
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, _createElement.apply(this, [
                                                'span',
                                                { 'className': 'cm_review-stars' },
                                                _map([
                                                    0,
                                                    1,
                                                    2,
                                                    3,
                                                    4
                                                ], repeatI2.bind(this))
                                            ]), this.value !== '5' ? _createElement('span', {
                                                'className': 'cm_and-up',
                                                'key': '499'
                                            }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, _createElement('div', {
                                    'className': 'facettitle',
                                    'data-cm-role': 'toggle-facet',
                                    'tabIndex': '0'
                                }, _createElement('span', {}, this.name), this.isCollapsed ? [_createElement('svg', {
                                        'className': 'cm_icon-angle left',
                                        'width': '15',
                                        'height': '9',
                                        'viewBox': '0 0 15 9',
                                        'fill': 'none',
                                        'xmlns': 'http://www.w3.org/2000/svg',
                                        'key': '1650'
                                    }, _createElement('path', {
                                        'd': 'M13.25 1.5L7.625 7.5L2 1.5',
                                        'stroke': '#FF6800',
                                        'strokeWidth': '3',
                                        'strokeLinecap': 'round',
                                        'strokeLinejoin': 'round'
                                    }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                        'width': '15',
                                        'height': '9',
                                        'viewBox': '0 0 15 9',
                                        'fill': 'none',
                                        'xmlns': 'http://www.w3.org/2000/svg',
                                        'key': '4670'
                                    }, _createElement('path', {
                                        'd': 'M13.25 1.5L7.625 7.5L2 1.5',
                                        'stroke': '#FF6800',
                                        'strokeWidth': '3',
                                        'strokeLinecap': 'round',
                                        'strokeLinejoin': 'round'
                                    }))] : null), _createElement('div', {
                                    'className': 'facetbody' + (this.template === 'simpleFacet' && this.needShowMore && !this.moreValuesToShow ? ' expanded' : ''),
                                    'role': 'list'
                                }, this.template === 'simpleFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_simpleFacet',
                                    'key': '941'
                                }, this.showFilterInput ? _createElement('div', {
                                    'className': 'filter-input',
                                    'key': '1027'
                                }, [this.filterInput(function () {
                                        return _createElement('div', {
                                            'className': 'input  cm_filterInput',
                                            'placeholder': 'Enter'
                                        });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })], this.inputNotEmpty ? _createElement('span', {
                                    'className': 'filter-input_clear-container',
                                    'onClick': this.clearInput,
                                    'key': '1347'
                                }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                        'className': 'facetdiv',
                                        'key': '15161'
                                    }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_ShowAlwaysValues' },
                                    _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                ]), _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_Values' },
                                    _map(this.Values, repeatValues2.bind(this))
                                ]), this.needShowMore ? _createElement('div', {
                                    'className': 'facetdiv cm_show-all-container',
                                    'key': '3347'
                                }, _createElement('a', {
                                    'className': 'cm_show-all',
                                    'data-cm-role': 'toggle-show-more',
                                    'tabIndex': '0'
                                }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'selectFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_selectFacet',
                                    'key': '3673'
                                }, [this.select(function () {
                                        function repeatEntry1(entry, entryIndex) {
                                            return _createElement('option', {
                                                'disabled': this.loading,
                                                'value': entry.term,
                                                'key': entry.value
                                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                        }
                                        function repeatEntry2(entry, entryIndex) {
                                            return _createElement('option', {
                                                'disabled': this.loading,
                                                'value': entry.term,
                                                'key': entry.value
                                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                        }
                                        function repeatEntry3(entry, entryIndex) {
                                            return _createElement('option', {
                                                'disabled': this.loading,
                                                'value': entry.term,
                                                'key': entry.value
                                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                        }
                                        function repeatEntry4(entry, index) {
                                            return _createElement('div', {
                                                'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                                'key': entry.value,
                                                'onClick': () => this.onChange(entry.term)
                                            }, this.showCheckboxes ? _createElement('input', {
                                                'type': 'checkbox',
                                                'readOnly': true,
                                                'checked': entry.selected,
                                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                                'key': '3543'
                                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                                'className': 'cm_dropdown_hitCount',
                                                'key': '3745'
                                            }, entry.hitCount) : null);
                                        }
                                        return _createElement('div', { 'className': 'cm_select' }, _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                            'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                            'key': '62'
                                        }, _createElement('select', {
                                            'className': 'cm_select_inner-select cm_select__pretty',
                                            'onChange': this.onChange,
                                            'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                            'disabled': this.disabled
                                        }, this.useNativeDropdown ? [
                                            !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                                'value': '',
                                                'key': 'null-option'
                                            }, '\n          ', '', this.title, '\n        ') : null,
                                            this.loading ? _createElement('option', {
                                                'key': 'loading-option',
                                                'disabled': true
                                            }, '...loading...') : null,
                                            this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                                _createElement('option', {
                                                    'className': 'cm_option_title',
                                                    'disabled': true,
                                                    'key': '7771'
                                                }, 'Popular Makes'),
                                                _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                                _createElement('option', {
                                                    'className': 'cm_option_title',
                                                    'disabled': true,
                                                    'key': '7774'
                                                }, 'All Makes'),
                                                _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                            ] : null,
                                            this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                                        ] : null, !this.useNativeDropdown ? _createElement('option', {
                                            'key': '_current',
                                            'value': '_current'
                                        }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                                    return _createElement('div', { 'className': 'cm_filterInput' });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                            'div',
                                            {
                                                'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                                'key': '2865'
                                            },
                                            !this.hideNullOption ? _createElement('div', {
                                                'className': 'option',
                                                'key': 'null-option',
                                                'onClick': () => this.onChange('')
                                            }, '\n      ', '', this.title, '\n    ') : null,
                                            this.loading ? _createElement('div', {
                                                'className': 'option',
                                                'key': 'null-option',
                                                'disabled': true
                                            }, '...loading...') : null,
                                            !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                                        ]) : null));
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]) : null, this.template === 'priceFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_priceFacet',
                                    'key': '9576'
                                }, !this.ranges.length ? [
                                    _createElement('div', { 'key': '96601' }, '$', this.selection[0], ' to $', this.selection[1]),
                                    '\n  ',
                                    this.slider,
                                    '\n  ',
                                    _createElement('div', {
                                        'className': 'cm_flex',
                                        'key': '96603'
                                    }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: $', this.min), _createElement('div', {}, 'Max: $', this.max))
                                ] : null, this.ranges.length ? [_createElement.apply(this, [
                                        'div',
                                        {
                                            'className': 'cmRepeater_Values',
                                            'key': '99281'
                                        },
                                        _map(this.Values, repeatValues3.bind(this))
                                    ])] : null, [this.Inputs(function () {
                                        return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                                return _createElement('div', {
                                                    'placeholder': 'Min price',
                                                    'className': 'cm_inputMin'
                                                });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                                return _createElement('div', {
                                                    'placeholder': 'Max price',
                                                    'className': 'cm_inputMax'
                                                });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })], _createElement('button', {
                                            'type': 'button',
                                            'className': 'cm_btn cm_button cm_button__secondary',
                                            'data-cm-role': 'add-facet'
                                        }, '\n    GO\n  '));
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]) : null, this.template === 'sliderFacet' ? scopeDollarInchMm4.apply(this, []) : null, this.template === 'reviewFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_reviewFacet',
                                    'key': '14382'
                                }, _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_ShowAlwaysValues' },
                                    _map(this.ShowAlwaysValues, repeatShowAlwaysValues5.bind(this))
                                ]), _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_Values' },
                                    _map(this.Values, repeatValues6.bind(this))
                                ])) : null));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_facets' },
                        _map(this.facets, repeatFacets1.bind(this))
                    ]));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:filterChips","cm:filterInput","cm:filterInput","cm:select","cm:inputMin","cm:inputMax","cm:Inputs","cm:inputMin","cm:inputMax","cm:Inputs","cm:facets","cm:FacetPanel"]