
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function singleVehicleGarageRT () {
    return _createElement('div', { 'className': 'garage-container' }, this.template === 'active' ? _createElement('div', {
        'className': 'active-container cmTemplate_active',
        'key': '33'
    }, [_createElement('svg', {
            'width': '25',
            'height': '22',
            'viewBox': '0 0 25 22',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '1310'
        }, _createElement('path', {
            'd': 'M9.79468 13.9274H15.7947V16.4139H9.79468V13.9274Z',
            'fill': 'white'
        }), _createElement('path', {
            'd': 'M22.2347 6.53095L20.9647 1.76919C20.8438 1.33258 20.591 0.948719 20.2441 0.675138C19.8973 0.401556 19.475 0.252991 19.0407 0.251709H6.54868C6.1145 0.253217 5.69248 0.401886 5.34581 0.675451C4.99914 0.949016 4.74648 1.33275 4.62568 1.76919L3.32568 6.53095H0.794678V8.62404H2.79468V15.9498C2.79521 16.5048 3.00609 17.0368 3.38105 17.4292C3.75601 17.8217 4.26441 18.0423 4.79468 18.0429V21.1825H6.79468V18.0429H18.7947V21.1825H20.7947V18.0429C21.3249 18.0423 21.8333 17.8217 22.2083 17.4292C22.5833 17.0368 22.7941 16.5048 22.7947 15.9498V8.62404H24.7947V6.53095H22.2347ZM6.54968 2.34479H19.0417L20.4697 7.5775H5.12068L6.54968 2.34479ZM20.7947 13.8567V15.9498H4.79468V13.8567H6.79468V11.7637H4.79468V9.67058H20.7947V11.7637H18.7947V13.8567H20.7947Z',
            'fill': 'white'
        }))], '\n    Select Your Vehicle\n  ') : null, this.template === 'locked' ? _createElement('div', {
        'className': 'locked-container cmTemplate_locked',
        'key': '1145'
    }, _createElement('div', { 'className': 'vehicle' }, _createElement('span', { 'className': 'year' }, this.vehicle.Year, ' ', this.vehicle.Make), _createElement('span', { 'className': 'model' }, this.vehicle.Model, ' ', this.vehicle.DriveType)), _createElement('span', { 'className': 'delimeter' }), _createElement('span', { 'className': 'change' }, 'Change')) : null);
}
        export const componentNames = []