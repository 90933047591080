
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function MainContentRT () {
    return _createElement('div', {
        'id': 'itemsBlock',
        'className': this.responseState === 'nothing' || this.responseState === 'badRequest' ? 'cm_hide' : ''
    }, [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '4950'
                }, _createElement('div', {}, '\n    These parts will not fit your ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    Parts shown below are based on keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '7690'
                }, '\n  No vehicle specific products found for\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Press ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to view parts that\n  fit this vehicle.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1136'
            }, 'We failed to process your request (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nYou may ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try to\nsearch for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1554'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any of the products we carry. We searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '1816'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nWe could not reach the search server.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2108'
            }, _createElement('div', {}, this.pageType !== 'search' && !this.isFacetsSelected ? [
                '\n    This ',
                this.pageType,
                ' doesn\'t have any\n    ',
                !!this.vehicleString ? [
                    ' parts that fit ',
                    _createElement('span', { 'key': '23371' }, '\'', this.vehicleString, '\'.')
                ] : null,
                !this.vehicleString ? [' products.'] : null
            ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '25411' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString ? [
                '\n    Press ',
                _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'onClick': this.startOver,
                    'key': '26721'
                }, 'here'),
                ' to clear\n    ',
                !this.vehicleString ? [' selection. '] : null,
                this.vehicleString && !this.isFacetsSelected ? ['\n      vehicle selection and browse all parts.\n    '] : null
            ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '3065'
            }, 'The request took too long.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '3280'
            }, 'We were unable to find products that match entire query ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. We\nperformed a partial search instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '3509'
            }, 'We failed to process your request', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '36550' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '37420' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '38250' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '36204' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.customMessage(function () {
            return _createElement('div', { 'className': 'cm_message cm_customMessage' }, []);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'cm_mobile-hide cm_SearchHeader' }, [this.facetDialogButton(function () {
                    return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                            return _createElement('div', { 'className': 'cm_button cm_button__primary cm_dialogButton' }, '\n  Filters ', [_createElement('svg', {
                                    'width': '15',
                                    'height': '9',
                                    'viewBox': '0 0 15 9',
                                    'fill': 'none',
                                    'xmlns': 'http://www.w3.org/2000/svg',
                                    'key': '690'
                                }, _createElement('path', {
                                    'd': 'M13.25 1.5L7.625 7.5L2 1.5',
                                    'stroke': '#FF6800',
                                    'strokeWidth': '3',
                                    'strokeLinecap': 'round',
                                    'strokeLinejoin': 'round'
                                }))]);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('div', {
                'className': 'cm_total-hits',
                'role': 'status'
            }, _createElement('span', {}, this.totalHits, ' results')), _createElement('div', { 'className': 'cm_sort sort-by' }, _createElement('span', { 'className': 'sort-label' }, 'SORT BY ', [_createElement('svg', {
                    'width': '15',
                    'height': '9',
                    'viewBox': '0 0 15 9',
                    'fill': 'none',
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'key': '12100'
                }, _createElement('path', {
                    'd': 'M13.25 1.5L7.625 7.5L2 1.5',
                    'stroke': '#FF6800',
                    'strokeWidth': '3',
                    'strokeLinecap': 'round',
                    'strokeLinejoin': 'round'
                }))]), '\n  ', this.sortSelect, '\n'));
        }, {
            widgetName: 'search-header',
            items: undefined
        })], [this.SearchResult(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        function mergeProps(inline, external) {
                            var res = Object.assign({}, inline, external);
                            if (inline.hasOwnProperty('style')) {
                                res.style = _.defaults(res.style, inline.style);
                            }
                            if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                res.className = external.className + ' ' + inline.className;
                            }
                            return res;
                        }
                        return _createElement('div', {
                            'className': 'custom-product-grid-item product-grid-item',
                            'data-grid-item': true
                        }, _createElement('div', { 'className': 'product-grid-img' }, _createElement('a', {
                            'href': this.url,
                            'data-grid-link': true,
                            'className': 'lazy-image',
                            'aria-label': this.removeHTML(this.title)
                        }, _createElement('img', {
                            'src': this.imageOrDefault(this.resizeImage(this.image)),
                            'alt': this.removeHTML(this.title),
                            'onError': this.onImageError,
                            'className': 'lazyautosizes lazyloaded'
                        })), this.product_card_badge ? _createElement('div', mergeProps({
                            'className': 'cm_badge',
                            'key': '397'
                        }, { dangerouslySetInnerHTML: { __html: this.product_card_badge } })) : null), _createElement('div', { 'className': 'product__grid__info product__grid__info--under text-left' }, _createElement('div', {}, _createElement('div', { 'className': 'product__grid__title__wrapper' }, _createElement('div', { 'className': 'custom-product__grid__title' }, this.display_custom_title ? _createElement('div', mergeProps({
                            'className': 'meta-title',
                            'key': '728'
                        }, { dangerouslySetInnerHTML: { __html: this.display_custom_title } })) : null, !this.display_custom_title ? [
                            _createElement('div', mergeProps({
                                'className': 'vendor copy copy--medium',
                                'key': '8731'
                            }, { dangerouslySetInnerHTML: { __html: this.vendor } })),
                            _createElement('a', mergeProps({
                                'className': 'h6',
                                'href': this.url,
                                'key': '8733'
                            }, { dangerouslySetInnerHTML: { __html: this.title } }))
                        ] : null), this.stock === 'In Stock' ? _createElement('div', {
                            'className': 'stock-info in-stock copy copy--medium',
                            'key': '1188'
                        }, [_createElement('svg', {
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'fill': 'none',
                                'viewBox': '0 0 20 20',
                                'key': '12940'
                            }, _createElement('rect', {
                                'width': '18',
                                'height': '18',
                                'x': '1',
                                'y': '1',
                                'fill': '#48C11E',
                                'stroke': '#48C11E',
                                'strokeWidth': '2',
                                'rx': '9'
                            }), _createElement('path', {
                                'stroke': '#fff',
                                'strokeLinecap': 'square',
                                'strokeLinejoin': 'round',
                                'strokeWidth': '2',
                                'd': 'm6.155 10.585 2.354 2.353 6.015-6.015'
                            }))], '\n          In Stock\n        ') : null, this.stock === 'Low Stock' ? _createElement('div', {
                            'className': 'stock-info low-stock copy copy--medium',
                            'key': '1670'
                        }, [_createElement('svg', {
                                'width': '20',
                                'height': '20',
                                'viewBox': '0 0 20 20',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'key': '17780'
                            }, _createElement('rect', {
                                'x': '1',
                                'y': '1',
                                'width': '18',
                                'height': '18',
                                'rx': '9',
                                'fill': '#FF6800',
                                'stroke': '#FF6800',
                                'strokeWidth': '2'
                            }), _createElement('g', { 'clipPath': 'url(#clip0_828_47067)' }, _createElement('path', {
                                'd': 'M14.993 5.07788L4.99304 15.0779',
                                'stroke': 'white',
                                'strokeWidth': '2',
                                'strokeLinecap': 'square',
                                'strokeLinejoin': 'round'
                            }), _createElement('path', {
                                'd': 'M4.99304 5.07788L14.993 15.0779',
                                'stroke': 'white',
                                'strokeWidth': '2',
                                'strokeLinecap': 'square',
                                'strokeLinejoin': 'round'
                            })), _createElement('rect', {
                                'x': '1',
                                'y': '1',
                                'width': '18',
                                'height': '18',
                                'rx': '9',
                                'fill': '#FFB800',
                                'stroke': '#FFB800',
                                'strokeWidth': '2'
                            }), _createElement('g', { 'clipPath': 'url(#clip1_828_47067)' }, _createElement('path', {
                                'd': 'M11.4592 4.368L11.1392 12.032H8.81919L8.49919 4.368H11.4592ZM10.0192 16.128C9.53919 16.128 9.14452 15.9893 8.83519 15.712C8.53652 15.424 8.38719 15.072 8.38719 14.656C8.38719 14.2293 8.53652 13.872 8.83519 13.584C9.14452 13.296 9.53919 13.152 10.0192 13.152C10.4885 13.152 10.8725 13.296 11.1712 13.584C11.4805 13.872 11.6352 14.2293 11.6352 14.656C11.6352 15.072 11.4805 15.424 11.1712 15.712C10.8725 15.9893 10.4885 16.128 10.0192 16.128Z',
                                'fill': 'white'
                            })), _createElement('defs', {}, _createElement('clipPath', { 'id': 'clip0_828_47067' }, _createElement('rect', {
                                'width': '10',
                                'height': '10',
                                'fill': 'white',
                                'transform': 'translate(5 5)'
                            })), _createElement('clipPath', { 'id': 'clip1_828_47067' }, _createElement('rect', {
                                'width': '20',
                                'height': '20',
                                'fill': 'white'
                            }))))], '\n          Low Stock\n        ') : null, this.out_of_stock ? _createElement('div', {
                            'className': 'stock-info out-of-stock copy copy--medium',
                            'key': '3212'
                        }, [_createElement('svg', {
                                'width': '20',
                                'height': '20',
                                'viewBox': '0 0 20 20',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'key': '33040'
                            }, _createElement('rect', {
                                'x': '1',
                                'y': '1',
                                'width': '18',
                                'height': '18',
                                'rx': '9',
                                'fill': '#FF0000',
                                'stroke': '#FF0000',
                                'strokeWidth': '2'
                            }), _createElement('g', { 'clipPath': 'url(#clip0_819_52756)' }, _createElement('path', {
                                'd': 'M14.993 5.07788L4.99304 15.0779',
                                'stroke': 'white',
                                'strokeWidth': '2',
                                'strokeLinecap': 'square',
                                'strokeLinejoin': 'round'
                            }), _createElement('path', {
                                'd': 'M4.99304 5.07788L14.993 15.0779',
                                'stroke': 'white',
                                'strokeWidth': '2',
                                'strokeLinecap': 'square',
                                'strokeLinejoin': 'round'
                            })), _createElement('defs', {}, _createElement('clipPath', { 'id': 'clip0_819_52756' }, _createElement('rect', {
                                'width': '10',
                                'height': '10',
                                'fill': 'white',
                                'transform': 'translate(5 5)'
                            }))))], '\n          Out of Stock\n        ') : null, this.product_card_note ? _createElement('div', {
                            'className': 'stock-info card-note copy copy--medium',
                            'key': '4024'
                        }, [_createElement('svg', {
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'fill': 'none',
                                'viewBox': '0 0 20 20',
                                'key': '41180'
                            }, _createElement('rect', {
                                'width': '18',
                                'height': '18',
                                'x': '1',
                                'y': '1',
                                'fill': '#48C11E',
                                'stroke': '#48C11E',
                                'strokeWidth': '2',
                                'rx': '9'
                            }), _createElement('path', {
                                'stroke': '#fff',
                                'strokeLinecap': 'square',
                                'strokeLinejoin': 'round',
                                'strokeWidth': '2',
                                'd': 'm6.155 10.585 2.354 2.353 6.015-6.015'
                            }))], '\n          ', this.product_card_note, '\n        ') : null, _createElement('div', { 'className': 'price-container' }, _createElement('span', { 'className': 'price h5' + (this.on_sale ? ' on-sale' : '') }, this.formatPrice(this.price)), _createElement('span', { 'className': 'compare-at copy copy--small' }, this.formatPrice(this.compare_at_price)))))));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'card cm_SearchResult' }, _createElement.apply(this, [
                'div',
                {
                    'className': 'js-grid' + (this.view ? ` ${ this.view }` : '') + ' cmRepeater_items',
                    'data-grid-small': '2',
                    'data-grid-large': '4'
                },
                _map(this.items, repeatItems1.bind(this))
            ]), [this.message(function () {
                    return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                            'href': 'mailto:support@convermax.com',
                            'className': 'cm_message-link'
                        }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                            'className': 'cm_message__incompatible',
                            'key': '4950'
                        }, _createElement('div', {}, '\n    These parts will not fit your ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    Parts shown below are based on keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                            'className': 'cm_message__universal',
                            'key': '7690'
                        }, '\n  No vehicle specific products found for\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Press ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'href': this.searchByVehicleUrl
                        }, 'here'), ' to view parts that\n  fit this vehicle.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                        'className': 'cmTemplate_badRequest',
                        'key': '1136'
                    }, 'We failed to process your request (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nYou may ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.startOver
                    }, 'clear your search'), ' and try to\nsearch for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                        'className': 'cmTemplate_corrected',
                        'key': '1554'
                    }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any of the products we carry. We searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                        'className': 'cmTemplate_networkError',
                        'key': '1816'
                    }, 'Network error\n', this.error ? [
                        '(id: ',
                        this.error.userId,
                        ', timestamp: ',
                        this.error.timestamp,
                        ', instance: ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nWe could not reach the search server.\n') : null, this.template === 'nothing' ? _createElement('div', {
                        'className': 'cmTemplate_nothing',
                        'key': '2108'
                    }, _createElement('div', {}, this.pageType !== 'search' && !this.isFacetsSelected ? [
                        '\n    This ',
                        this.pageType,
                        ' doesn\'t have any\n    ',
                        !!this.vehicleString ? [
                            ' parts that fit ',
                            _createElement('span', { 'key': '23371' }, '\'', this.vehicleString, '\'.')
                        ] : null,
                        !this.vehicleString ? [' products.'] : null
                    ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '25411' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString ? [
                        '\n    Press ',
                        _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.startOver,
                            'key': '26721'
                        }, 'here'),
                        ' to clear\n    ',
                        !this.vehicleString ? [' selection. '] : null,
                        this.vehicleString && !this.isFacetsSelected ? ['\n      vehicle selection and browse all parts.\n    '] : null
                    ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                        'className': 'cmTemplate_timeout',
                        'key': '3065'
                    }, 'The request took too long.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                        'className': 'cmTemplate_partial',
                        'key': '3280'
                    }, 'We were unable to find products that match entire query ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. We\nperformed a partial search instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                        'className': 'cmTemplate_unknown',
                        'key': '3509'
                    }, 'We failed to process your request', this.error ? [
                        '\n  (',
                        this.error.status ? [
                            _createElement('b', { 'key': '36550' }, 'status:'),
                            ' ',
                            this.error.status,
                            ', '
                        ] : null,
                        this.error.userId ? [
                            _createElement('b', { 'key': '37420' }, 'id:'),
                            ' ',
                            this.error.userId,
                            ', '
                        ] : null,
                        this.error.timestamp ? [
                            _createElement('b', { 'key': '38250' }, 'timestamp:'),
                            ' ',
                            this.error.timestamp,
                            ', '
                        ] : null,
                        _createElement('b', { 'key': '36204' }, 'instance:'),
                        ' ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], [this.loadMore(function () {
                    return _createElement('div', { 'className': 'cm_loadMore' }, _createElement('div', { 'className': 'cm_load-more' }, this.isNotAllItemsReceived ? [
                        this.isActive ? [this.loading] : null,
                        !this.isActive ? [[this.loadMoreButton(function () {
                                    return _createElement('div', {
                                        'className': 'btn btn-default btn-inverse cm_button cm_button__primary cm_loadMoreButton',
                                        'onClick': this.activate
                                    }, '\n        LOAD MORE\n      ');
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null
                    ] : null));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'results',
            items: undefined
        })], [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'cm_desktop-hide cm_SearchHeader' }, [this.facetDialogButton(function () {
                    return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                            return _createElement('div', { 'className': 'cm_button cm_button__primary cm_dialogButton' }, '\n  Filters ', [_createElement('svg', {
                                    'width': '15',
                                    'height': '9',
                                    'viewBox': '0 0 15 9',
                                    'fill': 'none',
                                    'xmlns': 'http://www.w3.org/2000/svg',
                                    'key': '690'
                                }, _createElement('path', {
                                    'd': 'M13.25 1.5L7.625 7.5L2 1.5',
                                    'stroke': '#FF6800',
                                    'strokeWidth': '3',
                                    'strokeLinecap': 'round',
                                    'strokeLinejoin': 'round'
                                }))]);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('div', {
                'className': 'cm_total-hits',
                'role': 'status'
            }, _createElement('span', {}, this.totalHits, ' results')), _createElement('div', { 'className': 'cm_sort sort-by' }, _createElement('span', { 'className': 'sort-label' }, 'SORT BY ', [_createElement('svg', {
                    'width': '15',
                    'height': '9',
                    'viewBox': '0 0 15 9',
                    'fill': 'none',
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'key': '12110'
                }, _createElement('path', {
                    'd': 'M13.25 1.5L7.625 7.5L2 1.5',
                    'stroke': '#FF6800',
                    'strokeWidth': '3',
                    'strokeLinecap': 'round',
                    'strokeLinejoin': 'round'
                }))]), '\n  ', this.sortSelect, '\n'));
        }, {
            widgetName: 'search-header',
            items: undefined
        })]);
}
        export const componentNames = ["cm:message","cm:customMessage","cm:dialogButton","cm:facetDialogButton","cm:SearchHeader","cm:message","cm:loadMoreButton","cm:loadMore","cm:SearchResult","cm:dialogButton","cm:facetDialogButton","cm:SearchHeader"]