
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchBoxRT () {
    return _createElement('form', { 'onSubmit': this.preventFormSubmission }, _createElement('div', { 'className': 'cm_search-box_form-container' }, [
        [this.searchInput(function () {
                return _createElement('div', { 'className': 'cm_searchInput' }, 'Search by Part Name, Part Number, or Brand');
            }, {
                widgetName: 'undefined',
                items: undefined
            })],
        _createElement('span', {
            'className': 'cm_search-box_clear-container',
            'key': '972'
        }, this.inputNotEmpty ? _createElement('span', {
            'className': 'cm_search-box_clear',
            'onClick': this.clearInput,
            'key': '420'
        }, '\n    \u2715\n  ') : null),
        _createElement('div', {
            'title': 'Search',
            'aria-label': 'search button',
            'className': 'cm_search-box_button',
            'data-cm-role': 'add-query',
            'key': '974'
        }, [_createElement('svg', {
                'width': '22',
                'height': '21',
                'viewBox': '0 0 22 21',
                'fill': 'none',
                'xmlns': 'http://www.w3.org/2000/svg',
                'key': '6450'
            }, _createElement('path', {
                'd': 'M20.128 19.5851L14.4069 13.864M14.4069 13.864C15.7792 12.4917 16.628 10.5959 16.628 8.50179C16.628 4.31363 13.2328 0.918457 9.04464 0.918457C4.85648 0.918457 1.4613 4.31363 1.4613 8.50179C1.4613 12.69 4.85648 16.0851 9.04464 16.0851C11.1387 16.0851 13.0346 15.2363 14.4069 13.864Z',
                'stroke': '#FF6800',
                'strokeWidth': '1.8',
                'strokeLinecap': 'round',
                'strokeLinejoin': 'round'
            }))])
    ]), [this.dropdown(function () {
            function repeatSections1(hasProductResults, sections, sectionsIndex) {
                return [sections(function () {
                        function repeatItems1(isProducts, items, itemsIndex) {
                            return [items(function () {
                                    function mergeProps_text(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    function mergeProps_product(inline, external) {
                                        var res = Object.assign({}, inline, external);
                                        if (inline.hasOwnProperty('style')) {
                                            res.style = _.defaults(res.style, inline.style);
                                        }
                                        if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                            res.className = external.className + ' ' + inline.className;
                                        }
                                        return res;
                                    }
                                    return this.template === 'text' ? _createElement('div', mergeProps_text({ 'className': (this.text === this.query ? 'active' : '') + ' cmTemplate_text' }, { dangerouslySetInnerHTML: { __html: this.textHighlight } })) : this.template === 'product' ? _createElement('div', { 'className': 'cmTemplate_product' }, _createElement('div', {
                                        'className': 'custom-product-grid-item product-grid-item',
                                        'data-grid-item': true
                                    }, _createElement('div', { 'className': 'product-grid-img' }, _createElement('a', {
                                        'href': this.url,
                                        'data-grid-link': true,
                                        'className': 'lazy-image',
                                        'aria-label': this.removeHTML(this.title)
                                    }, _createElement('img', {
                                        'src': this.imageOrDefault(this.image),
                                        'alt': this.removeHTML(this.title),
                                        'onError': this.onImageError,
                                        'className': 'lazyautosizes lazyloaded'
                                    })), this.product_card_badge ? _createElement('div', mergeProps_product({
                                        'className': 'cm_badge',
                                        'key': '411'
                                    }, { dangerouslySetInnerHTML: { __html: this.product_card_badge } })) : null), _createElement('div', { 'className': 'product__grid__info product__grid__info--under text-left' }, _createElement('div', {}, _createElement('div', { 'className': 'product__grid__title__wrapper' }, _createElement('div', { 'className': 'custom-product__grid__title' }, this.display_custom_title ? _createElement('div', mergeProps_product({
                                        'className': 'meta-title',
                                        'key': '742'
                                    }, { dangerouslySetInnerHTML: { __html: this.display_custom_title } })) : null, !this.display_custom_title ? [
                                        _createElement('div', mergeProps_product({
                                            'className': 'vendor copy copy--medium',
                                            'key': '8871'
                                        }, { dangerouslySetInnerHTML: { __html: this.vendor } })),
                                        _createElement('a', mergeProps_product({
                                            'className': 'h6',
                                            'href': this.url,
                                            'key': '8873'
                                        }, { dangerouslySetInnerHTML: { __html: this.title } }))
                                    ] : null), _createElement('div', { 'className': 'price-container' }, _createElement('span', { 'className': 'price h5' + (this.on_sale ? ' on-sale' : '') }, this.formatPrice(this.price)), _createElement('span', { 'className': 'compare-at copy copy--small' }, this.formatPrice(this.compare_at_price)))))))) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        function scopeIsProducts2() {
                            var isProducts = this.sectionTitle === 'Products';
                            return _createElement('div', {
                                'className': 'section' + (isProducts ? ' products' : hasProductResults ? ' queries' : ''),
                                'key': '3'
                            }, this.sectionTitle ? _createElement('div', {
                                'className': 'section-title-div',
                                'key': '217'
                            }, _createElement('div', { 'className': 'item section-title' }, '\n        ', isProducts ? 'Product Suggestions' : this.sectionTitle, !!this.vehicleString ? ` for
        ${ this.vehicleString }` : '', '\n      ')) : null, _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_items' },
                                _map(this.items, repeatItems1.bind(this, isProducts))
                            ]), isProducts ? [[this.browseAllButton(function () {
                                        return _createElement('a', { 'className': 'item button-item cm_browseAllButton' }, '\n        See All Results For ', _createElement('span', {}, '"', this.query, '"'), [_createElement('svg', {
                                                'width': '8',
                                                'height': '14',
                                                'viewBox': '0 0 8 14',
                                                'fill': 'none',
                                                'xmlns': 'http://www.w3.org/2000/svg',
                                                'key': '1140'
                                            }, _createElement('path', {
                                                'd': 'M2 2.3125L7 7L2 11.6875',
                                                'stroke': '#020202',
                                                'strokeWidth': '2',
                                                'strokeLinecap': 'square',
                                                'strokeLinejoin': 'round'
                                            }))]);
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]] : null);
                        }
                        return this.items.length ? scopeIsProducts2.apply(this, []) : null;
                    }, { count: undefined })];
            }
            function scopeHasProductResults2() {
                var hasProductResults = this.hasProductResults;
                return _createElement.apply(this, [
                    'div',
                    { 'className': 'cmRepeater_sections' },
                    _map(this.sections, repeatSections1.bind(this, hasProductResults))
                ]);
            }
            return _createElement('div', { 'className': 'cm_autocomplete' + (!this.hasProductResults ? ' no-products' : '') + ' cm_dropdown' }, scopeHasProductResults2.apply(this, []), !this.hasProductResults ? _createElement('div', {
                'className': 'section message',
                'key': '6320'
            }, _createElement('span', { 'className': 'primary-text' }, ' Sorry, there are no products matching your selected vehicle. '), '\n  Feeling stuck? We\'ve got you!\n  ', _createElement('span', { 'onClick': () => Reamaze?.popup() }, [_createElement('svg', {
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'fill': 'none',
                    'viewBox': '0 0 19 19',
                    'width': '16',
                    'height': '16',
                    'key': '65690'
                }, _createElement('path', {
                    'fill': '#000',
                    'd': 'M.75 3.148A2.25 2.25 0 0 1 3 .898h13.5a2.25 2.25 0 0 1 2.25 2.25v9a2.25 2.25 0 0 1-2.25 2.25H5.716c-.299 0-.585.118-.796.33l-3.21 3.209a.563.563 0 0 1-.96-.397V3.148Zm5.625 4.5a1.125 1.125 0 1 0-2.25 0 1.125 1.125 0 0 0 2.25 0Zm4.5 0a1.125 1.125 0 1 0-2.25 0 1.125 1.125 0 0 0 2.25 0Zm3.375 1.125a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z'
                }))], _createElement('a', {}, 'CHAT WITH AN EXPERT'))) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], _createElement('span', { 'className': 'cm_triangle' }));
}
        export const componentNames = ["cm:searchInput","cm:browseAllButton","cm:dropdown"]