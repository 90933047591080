//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-556:_4248,_7252,_880,_9956,_2968,_332,_1636,_7780;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-556')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-556', "_4248,_7252,_880,_9956,_2968,_332,_1636,_7780");
        }
      }catch (ex) {
        console.error(ex);
      }