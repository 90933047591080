import { AfterInit as cartVerifyAfterInit } from './cartPageVerifyFitment.js';

const baseFitmentFields = ['Year', 'Make', 'Model', 'DriveType'];
const extraFitmentFields = [];
const wheelsFields = ['wheel_diameter', 'wheel_width', 'wheel_bolt_pattern'];
const tiresFields = ['tire_width', 'tire_aspect', 'tire_rim'];
const baseIgnoreFields = [...wheelsFields, ...tiresFields, 'Universal', 'Fitment'];
const ignoreFields = window.Convermax.isVendorCollection
  ? [...baseIgnoreFields, 'vendor']
  : window.Convermax.isRegularCollection
    ? [...baseIgnoreFields, 'collections']
    : baseIgnoreFields;

const lsScrollKey = 'cm_scroll-to-results';
const desktopHeaderVehicleWidgetId = '#cm_HeaderVehicleWidget';
const mobileHeaderVehicleWidgetId = '#cm_HeaderVehicleWidget_mobile';

globalThis.Convermax.onSelectVehicleBtnClick = (e, selectVehicle) => {
  if (
    !window.document.body.classList.contains('cm_hasSearchPage') &&
    [desktopHeaderVehicleWidgetId, mobileHeaderVehicleWidgetId].some((id) => e.target.closest(id))
  ) {
    window.localStorage.setItem(lsScrollKey, true);
  }

  selectVehicle();
};

const scrollToSearchResults = () => {
  const { document, innerWidth, scroll } = window;

  const searchResultsContainer = document.querySelector('[data-section-type="search-page"]');

  if (searchResultsContainer) {
    const header =
      innerWidth < 1300
        ? document.querySelector('.theme__header')
        : document.querySelector('.header__desktop .top');

    scroll(0, searchResultsContainer.offsetTop - (header?.offsetHeight ?? 0));
  }
};

function showVehicleWidget(e, trigger, selectorToHide) {
  const { document, innerWidth } = window;
  const isMobile = innerWidth < 1300;

  const elementToHide = document.querySelector(selectorToHide);
  const clickedElement = e.target;

  if (clickedElement.closest(trigger)) {
    elementToHide.classList.toggle('cm_vehicle-widget-hide');
    elementToHide.classList.remove('cm_vehicle-widget-init');

    if (isMobile) {
      document.body.classList.add('cm_no-scroll');
      document.documentElement.classList.add('cm_no-scroll');
    }
  }

  if (
    !elementToHide.classList.contains('cm_vehicle-widget-hide') &&
    (clickedElement.closest('.close') ||
      clickedElement.closest('.cm_vehicle-widget_button__go') ||
      (!clickedElement.closest(trigger) && !clickedElement.closest(selectorToHide)) ||
      (isMobile && clickedElement.classList.contains('vehicle-dialog')))
  ) {
    elementToHide.classList.add('cm_vehicle-widget-hide');
    document.body.classList.remove('cm_no-scroll');
    document.documentElement.classList.remove('cm_no-scroll');
  }
}

const AfterInit = () => {
  window.addEventListener('click', (e) => {
    const { document } = window;

    if (e.target.closest('#cm_search-box__mobile')) {
      document.body.classList.add('cm_no-iframe');
    } else {
      document.body.classList.remove('cm_no-iframe');
    }

    showVehicleWidget(e, '#cm_garage', desktopHeaderVehicleWidgetId);
    showVehicleWidget(e, '#cm_garage__mobile', mobileHeaderVehicleWidgetId);
  });

  window.document.addEventListener('scroll', () => {
    const searchResults = $('.cm_SearchResult');
    const searchHeader = $('.cm_desktop-hide.cm_SearchHeader');

    if (searchResults.length && searchHeader.length) {
      const windowBottom = $(window).scrollTop() + $(window).height();
      const searchResultsBottom = $(searchResults).offset().top + $(searchResults).height();

      if (searchResultsBottom <= windowBottom) {
        searchHeader.css('display', 'none');
      } else {
        searchHeader.removeAttr('style');
      }
    }
  });

  if (localStorage.getItem(lsScrollKey)) {
    scrollToSearchResults();
    localStorage.removeItem(lsScrollKey);
  }

  cartVerifyAfterInit();
};

export default {
  platform: 'shopify',
  searchPageUrl: '/search',
  AfterInit,
  disableRequestScroll: true,
  SearchRequestDefaults: {
    pageSize: 36,
  },
  autocomplete: { filterProductsOnQueryClick: true },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    expectResponseRedirect: true,
    isAutoVehicleSelectionDisabled: true,
    forceVehicleSelection: true,
    isVehicleSelectionIsolated: true,
  },
  facets: {
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['wheel_offset'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'FacetPanel',
      ignoreFields,
      initCollapsed: true,
      initExpandedFacets: ['stock', 'Position', 'Lift'],
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#cm_collection-results',
      template: 'MainContent',
    },
    {
      name: 'CategoryFacetPanel',
      type: 'SearchPage',
      location: '#cm_facet-panel',
      template: 'FacetPanelContainer',
      initCollapsed: true,
      initExpandedFacets: ['stock', 'Position'],
    },
    {
      name: 'SearchBox',
      location: { selector: '#cm_search-box', class: 'cm_search-box-root__desktop' },
      template: 'SearchBox',
    },
    {
      /* The client EXPLICITLY asked not to add the dialog */
      name: 'SearchBoxMobile',
      type: 'SearchBox',
      location: {
        selector: '#cm_search-box__mobile',
        class: 'cm_search-box-root__mobile cm_desktop-hide',
      },
      template: 'SearchBox',
    },
    {
      name: 'SearchResult',
      infiniteScrollDisabled: true,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'collection_sort': 'Featured',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
      },
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm_vehicle-widget__home',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 900,
    },
    {
      name: 'HeroBanner',
      type: 'SearchPage', // to provide access to query and total hits
      location: {
        selector: '#cm_vehicle-widget__search',
        class: 'cm_vehicle-widget__search-container',
      },
      template: 'HeroBanner',
      columnBreakpoint: 1100,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      columnBreakpoint: 1100,
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: {
        lastChildOf: '.header__desktop__bar__c',
        class: 'cm_dialog cm_dialog-dropdown vehicle-dialog cm_vehicle-widget-hide cm_mobile-hide',
      },
      template: 'fitmentSearch/headerVehicleWidget',
      columnBreakpoint: 900,
    },
    {
      name: 'HeaderVehicleWidget_mobile',
      type: 'VehicleWidget',
      location: {
        lastChildOf: '.theme__header',
        class: 'cm_dialog vehicle-dialog cm_vehicle-widget-hide cm_desktop-hide cm_vehicle-widget-init',
      },
      template: 'fitmentSearch/headerVehicleWidget',
      columnBreakpoint: 900,
    },
    {
      name: 'HeaderVehicleWidget_custom',
      type: 'VehicleWidget',
      location: {
        selector: '.cm-inline-ymmd, .cm-inline-ymmd-full',
        class: 'cm_vehicle-widget__custom',
      },
      template: 'fitmentSearch/customVehicleWidget',
      columnBreakpoint: 900,
    },
    {
      name: 'SingleVehicleGarage',
      location: { selector: '#cm_garage', class: 'cm_single-vehicle-garage' },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'SingleVehicleGarage_mobile',
      type: 'SingleVehicleGarage',
      location: {
        selector: '#cm_garage__mobile',
        class: 'cm_single-vehicle-garage cm_desktop-hide',
      },
      template: 'fitmentSearch/singleVehicleGarage',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: '#cm_verify-fitment',
      template: 'fitmentSearch/verifyFitment',
      columnBreakpoint: 1000,
    },
    {
      name: 'VerifyFitment_new',
      type: 'VehicleWidget',
      location: '#cm_verify-fitment-new',
      template: 'fitmentSearch/verifyFitmentNew',
      columnBreakpoint: 1000,
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
      globalVehicleDiscardEnabled: true,
      isAlwaysActive: true,
      columnBreakpoint: 1000,
      isAutoVehicleSelectionDisabled: false,
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      initCollapsed: false,
      ignoreFields,
    },
  ],
};
